import basketItem from './components/basket-item/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import navigateTo from '../../../../mixins/buy-flow'

export default {
  name: "buy-flow",
  mixins: [navigateTo],
  data() {
    return {
      kod: ''
    }
  },
  components: {
    basketItem
  },
  mounted() {
  },
  created() {
    this.sync()
  },
  computed: {
    ...mapGetters({
      basket: 'basket/basket',
      secondBasket: 'basket/secondBasket',
      defaultPrices: 'basket/defaultPrices',
      isProfessional: 'profile/isProfessional',
      user: 'profile/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    totalSum() {
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.select_type.price * currentValue.select_count);
      }, 0);
    },
    discount() {
      if (this.user && this.user.tempDiscount && !this.isProfessional) {

        return (this.totalSum * 5) / 100
      }
      let sum
      if (this.user && this.user.professional.data && this.user.professional.data.active || this.isProfessional) {
        sum = this.secondBasket.reduce((accumulator, currentValue) => {
          if (currentValue.select_type.defaultPrice) {
            return accumulator + (currentValue.select_type.defaultPrice * currentValue.select_count)
          } else {
            return accumulator + (currentValue.select_type.price * currentValue.select_count)
          }
        }, 0)
        return sum - this.totalSum
      } else {
        return 0
      }
    },

  },
  methods: {
    ...mapActions({
      getSync: 'basket/GET_SYNC_BASKET',
      fetchDefaultPrices: 'basket/GET_DEFAULT_PRICE_BASKET',
      updateCosmetologId: 'profile/UPDATE_USER_DATA',
      fetchUser: 'profile/FETCH_USER_DATA',
    }),
    syncProfessional() {
      this.updateCosmetologId({professional_id: this.kod}).then(() => {
        this.fetchUser()
        const parsed = JSON.stringify(this.secondBasket);
        localStorage.setItem('products', parsed)
        window.location.reload()
        this.$toasted.success(this.$t('successChangeCosmetolog'))
      }).catch(() => {
        this.$toasted.error(this.$t('errorCodeProfessional'))
      })
    },
    sync() {
      let ids = []
      for (let i in this.basket) {
        ids.push(this.basket[i].id)
      }
      this.getSync({ids: ids})
      this.fetchDefaultPrices({ids: ids})
    },
  },
}
